<template>
  <div class="product-grid bg-white">
    <div class="ss-header mb-3">
      <span class="title-desc"><strong>Товары</strong></span>
    </div>
    <ul id="myTab" class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="active-tab"
          data-toggle="tab"
          href="#active"
          role="tab"
          aria-controls="active"
          aria-selected="false"
        >
          Активные заказы
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="completed-tab"
          data-toggle="tab"
          href="#completed"
          role="tab"
          aria-controls="completed"
          aria-selected="true"
        >
          Выполненные заказы
        </a>
      </li>
    </ul>
    <div class="tab-content mb-5">
      <div role="tabpanel" class="tab-pane fade show active" id="active">
        <div
          class="flex-table"
          role="table"
          v-if="activeItems && activeItems.length > 0"
        >
          <div class="flex-table-row flex-table-header flex-row">
            <div class="flex-table-cell">Статус</div>
            <div class="flex-table-cell">Дата создания</div>
            <div class="flex-table-cell">Номер заказа</div>
            <div class="flex-table-cell">Стоимость</div>
          </div>
          <div id="accordion" class="flex-table-body accordion">
            <div
              class="flex-table-row flex-column accordion-item"
              v-for="item in activeItems"
              :key="item.id"
            >
              <div
                :id="'heading' + item.id"
                class="accordion-header flex-table-row"
              >
                <div
                  class="flex-table-row collapsed accordion-toggle"
                  role="button"
                  data-toggle="collapse"
                  :data-target="'#collapse' + item.id"
                  aria-expanded="true"
                  :aria-controls="'collapse' + item.id"
                >
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Статус:</b>
                    <span
                        class="product-order-status"
                        :class="item.education || item.services
                          ? getServiceStatusClass(item.status)
                          : getProductStatusClass(item.status)
                      "
                    >{{
                      item.status_name
                    }}</span>
                  </div>
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Дата создания:</b>
                    {{ item.date }}
                  </div>
                  <div class="flex-table-cell product-order_number">
                    <b class="d-lg-none">Номер заказа:</b> {{ item.number }}
                  </div>
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Стоимость:</b>
                    {{ item.amount | fractionSizeSpace }}
                    {{ makeDeclension(item.amount) }}
                  </div>
                </div>
              </div>
              <div
                v-if="item.items"
                :id="'collapse' + item.id"
                class="flex-table-row accordion-collapse collapse"
                :aria-labelledby="'heading' + item.id"
                data-parent="#accordion"
              >
                <div class="flex-table-cell px-0 py-0 w-100">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table class="table table-product-orders">
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th style="width: 140px">Цена</th>
                            <th style="width: 115px">Кол-во</th>
                            <th style="width: 140px">Сумма</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="productOrderItem in item.items"
                            :key="productOrderItem.id"
                          >
                            <th>
                              <a
                                class="cursor-pointer hover-underline"
                                @click="
                                  goToProductCardInOrders(
                                    productCardParams(productOrderItem)
                                  )
                                "
                                v-if="productOrderItem.active"
                                >{{ productOrderItem.name }}</a
                              >
                              <span v-else class="font-weight-bold">{{
                                productOrderItem.name
                              }}</span>
                            </th>
                            <td>
                              {{ productOrderItem.points | fractionSizeSpace }}
                              {{ makeDeclension(productOrderItem.points) }}
                            </td>
                            <td>{{ productOrderItem.quantity }}</td>
                            <td>
                              {{
                                (productOrderItem.points *
                                  productOrderItem.quantity)
                                  | fractionSizeSpace
                              }}
                              {{
                                makeDeclension(
                                  productOrderItem.points *
                                    productOrderItem.quantity
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row d-flex justify-content-center pb-3">
                      <div class="col-sm-7">
                        <div class="addr" v-if="item.delivery_address">
                          <span class="desc-title">Адрес доставки:</span>
                          <p>{{ item.delivery_address }}</p>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <button
                          class="send-to-basket float-right btn-full-size-xs"
                          type="button"
                          v-if="canCancelProduct(item.status)"
                          @click="cancelProductOrder(item.id)"
                        >
                          Отменить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="item.education"
                :id="'collapse' + item.id"
                class="flex-table-row accordion-collapse collapse"
                :aria-labelledby="'heading' + item.id"
                data-parent="#accordion"
              >
                <div class="flex-table-cell px-0 py-0 w-100">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table class="table table-visit-request-orders">
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th>Форма обучения</th>
                            <th>Дата обучения</th>
                            <th>Стоимость тарифа</th>
                            <th v-if="item.education.city">Город проведения</th>
                            <th>Стоимость за участника</th>
                            <th>Количество участников</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-label="Наименование">
                              <a class="cursor-pointer hover-underline">
                                {{ item.education.title }}
                              </a>
                            </td>
                            <td data-label="Форма обучения">
                              {{ item.education.format.indexOf('offline') >= 0 ? 'offline' : 'online' }}
                            </td>
                            <td data-label="Дата обучения">
                              {{ item.education.start_at }}
                            </td>
                            <td data-label="Стоимость тарифа">
                              {{ item.amount | fractionSizeSpace }}
                              {{ makeDeclension(item.amount) }}
                            </td>
                            <td
                              data-label="Город проведения"
                              v-if="item.education.city"
                            >
                              {{ item.education.city }}
                            </td>
                            <td data-label="Стоимость за участника">
                              {{
                                item.education.tariff_price | fractionSizeSpace
                              }}
                              {{ makeDeclension(item.education.tariff_price) }}
                            </td>
                            <td data-label="Количество участников">
                              {{ item.user_count }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row d-flex justify-content-center pb-3">
                      <div class="col-sm-7">
                        <span
                          v-if="item.remainder_by_contract"
                          class="desc-title"
                          >Оплата по контракту:
                          {{ item.remainder_by_contract | fractionSizeSpace }}
                          {{ makeDeclension(item.remainder_by_contract) }}</span
                        >
                      </div>
                      <div class="col-sm-5">
                        <button
                          class="send-to-basket float-right btn-full-size-xs"
                          type="button"
                          v-if="canCancelProduct(item.status)"
                          @click="cancelEducationOrder(item.id)"
                        >
                          Отменить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-md-center"
          v-if="!activeItems || !(activeItems.length > 0)"
        >
          <div class="ss-header">
            <span class="title-desc"
              ><strong>Тут пока ничего нет.</strong></span
            >
          </div>
        </div>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="completed">
        <div
          class="flex-table"
          role="table"
          v-if="completeItems && completeItems.length > 0"
        >
          <div class="flex-table-row flex-table-header flex-row">
            <div class="flex-table-cell">Статус</div>
            <div class="flex-table-cell">Дата создания</div>
            <div class="flex-table-cell">Номер заказа</div>
            <div class="flex-table-cell">Стоимость</div>
          </div>
          <div id="accordion2" class="flex-table-body accordion">
            <div
              class="flex-table-row flex-column accordion-item"
              v-for="item in completeItems"
              :key="item.id"
            >
              <div
                :id="'heading' + item.id"
                class="accordion-header flex-table-row"
              >
                <div
                  class="flex-table-row collapsed accordion-toggle"
                  role="button"
                  data-toggle="collapse"
                  :data-target="'#collapse' + item.id"
                  aria-expanded="true"
                  :aria-controls="'collapse' + item.id"
                >
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Статус:</b>
                    <span
                      class="product-order-status"
                      :class="
                        item.education || item.services
                          ? getServiceStatusClass(item.status)
                          : getProductStatusClass(item.status)
                      "
                    >
                      {{ item.status_name }}
                    </span>
                  </div>
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Дата создания:</b>
                    {{ item.date }}
                  </div>
                  <div class="flex-table-cell product-order_number">
                    <b class="d-lg-none">Номер заказа:</b> {{ item.number }}
                  </div>
                  <div class="flex-table-cell">
                    <b class="d-lg-none">Стоимость:</b>
                    {{ item.amount | fractionSizeSpace }}
                    {{ makeDeclension(item.amount) }}
                  </div>
                </div>
              </div>
              <div
                v-if="item.items"
                :id="'collapse' + item.id"
                class="flex-table-row accordion-collapse collapse"
                :aria-labelledby="'heading' + item.id"
                data-parent="#accordion2"
              >
                <div class="flex-table-cell px-0 py-0 w-100">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table class="table table-product-orders">
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th style="width: 140px">Цена</th>
                            <th style="width: 115px">Кол-во</th>
                            <th style="width: 140px">Сумма</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="productOrderItem in item.items"
                            :key="productOrderItem.id"
                          >
                            <th>
                              <a
                                class="cursor-pointer hover-underline"
                                @click="
                                  goToProductCardInOrders(
                                    productCardParams(productOrderItem)
                                  )
                                "
                                v-if="productOrderItem.active"
                                >{{ productOrderItem.name }}</a
                              >
                              <span v-else class="font-weight-bold">{{
                                productOrderItem.name
                              }}</span>
                            </th>
                            <td>
                              {{ productOrderItem.points | fractionSizeSpace }}
                              {{ makeDeclension(productOrderItem.points) }}
                            </td>
                            <td>{{ productOrderItem.quantity }}</td>
                            <td>
                              {{
                                (productOrderItem.points *
                                  productOrderItem.quantity)
                                  | fractionSizeSpace
                              }}
                              {{
                                makeDeclension(
                                  productOrderItem.points *
                                    productOrderItem.quantity
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row d-flex justify-content-center pb-3">
                      <div class="col-sm-7">
                        <div class="addr" v-if="item.delivery_address">
                          <span class="desc-title">Адрес доставки:</span>
                          <p>{{ item.delivery_address }}</p>
                        </div>
                      </div>
                      <div class="col-sm-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="item.education"
                :id="'collapse' + item.id"
                class="flex-table-row accordion-collapse collapse"
                :aria-labelledby="'heading' + item.id"
                data-parent="#accordion2"
              >
                <div class="flex-table-cell px-0 py-0 w-100">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table class="table table-visit-request-orders">
                        <thead>
                          <tr>
                            <th>Наименование</th>
                            <th>Форма обучения</th>
                            <th>Дата обучения</th>
                            <th>Стоимость тарифа</th>
                            <th v-if="item.education.city">Город проведения</th>
                            <th>Стоимость за участника</th>
                            <th>Количество участников</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-label="Наименование">
                              <a class="cursor-pointer hover-underline">
                                {{ item.education.title }}
                              </a>
                            </td>
                            <td data-label="Форма обучения">
                                {{ item.education.format.indexOf('offline') >= 0 ? 'offline' : 'online' }}
                            </td>
                            <td data-label="Дата обучения">
                              {{ item.education.start_at }}
                            </td>
                            <td data-label="Стоимость тарифа">
                              {{ item.amount | fractionSizeSpace }}
                              {{ makeDeclension(item.amount) }}
                            </td>
                            <td
                              data-label="Город проведения"
                              v-if="item.education.city"
                            >
                              {{ item.education.city }}
                            </td>
                            <td data-label="Стоимость за участника">
                              {{
                                item.education.tariff_price | fractionSizeSpace
                              }}
                              {{ makeDeclension(item.education.tariff_price) }}
                            </td>
                            <td data-label="Количество участников">
                              {{ item.user_count }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row d-flex justify-content-center pb-3">
                      <div class="col-sm-7">
                        <span
                          v-if="item.remainder_by_contract"
                          class="desc-title"
                          >Оплата по контракту:
                          {{ item.remainder_by_contract | fractionSizeSpace }}
                          {{ makeDeclension(item.remainder_by_contract) }}</span
                        >
                      </div>
                      <div class="col-sm-5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-md-center"
          v-if="!completeItems || !(completeItems.length > 0)"
        >
          <div class="ss-header">
            <span class="title-desc">
              <strong>Тут пока ничего нет.</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "@/lib/axios";
import { DeclensionMaker } from "@/utilities/declension-maker.ts";
import { showConfirm } from "@/lib/swal2";
import "bootstrap/js/src/tab";
import { mapGetters, mapActions } from "vuex";

const ProductStatuses = Object.freeze({
  Accepted: 1,
  Processing: 2,
  TransferredToDelivery: 3,
  Delivered: 4,
  Cancelled: 5,
});

const ServiceStatuses = Object.freeze({
  Accepted: 1,
  Confirmed: 2,
  Processing: 3,
  Cancelled: 4,
  Done: 5,
});

export default {
  name: "Orders",
  data: () => ({
    ProductStatuses,
    ServiceStatuses,
    activeItems: [],
    activeServiceItems: [],
    completeItems: [],
    completeServiceItems: [],
  }),
  methods: {
    ...mapActions("account", ["getUserInfo"]),
    ...mapActions("catalog", ["goToProductCardInOrders"]),
    getProductStatusClass(status) {
      let className = "";
      if (status === 1) {
        className = "accepted";
      } else if (status === 2) {
        className = "processing";
      } else if (status === 3) {
        className = "shipping";
      } else if (status === 4) {
        className = "done";
      } else if (status === 5) {
        className = "canceled";
      } else if (status === 6) {
          className = "payed";
      }
      return className;
    },
    getServiceStatusClass(status) {
      let className = "";
      if (status === 1) {
        className = "accepted";
      }
      if (status === 2) {
        className = "confirmed";
      }
      if (status === 3) {
        className = "processing";
      }
      if (status === 4) {
        className = "canceled";
      }
      if (status === 5) {
        className = "done";
      } else if (status === 6) {
          className = "payed";
      }
      return className;
    },
    async fetchData() {
      await get("profile/orders", { type: "active" }).then((response) => {
        this.activeItems = response.data.data.products.filter(item => !item.visit);
        /*this.activeServiceItems = response.data.data.services*/
      });
      await get("profile/orders", { type: "complete" }).then((response) => {
        this.completeItems = response.data.data.products.filter(item => !item.visit);
        /*this.completeServiceItems = response.data.data.services*/
      });
    },
    makeDeclension(price) {
      return DeclensionMaker.getDeclension(price, "балл", "балла", "баллов");
    },
    canCancelProduct(status) {
      return status === ProductStatuses.Accepted;
    },
    canCancelService(status) {
      return status === ServiceStatuses.Accepted;
    },
    async cancelProductOrder(orderId) {
      const { isConfirmed } = await showConfirm(
        "Вы уверены, что хотите отменить данный заказ?"
      );

      if (isConfirmed) {
        await post("product-order/cancel", { order_id: orderId });
        await this.fetchData();
        await this.getUserInfo();
      }
    },
    async cancelEducationOrder(orderId) {
      const { isConfirmed } = await showConfirm(
        "Вы уверены, что хотите отменить данный заказ?"
      );

      if (isConfirmed) {
        await post("education/course-order/cancel", { order_id: orderId });
        await this.fetchData();
        await this.getUserInfo();
      }
    },
    async cancelServiceOrder(orderId) {
      const { isConfirmed } = await showConfirm(
        "Вы уверены, что хотите отменить данный заказ?"
      );

      if (isConfirmed) {
        await post("visit-order/cancel", { order_id: orderId });
        await this.fetchData();
        await this.getUserInfo();
      }
    },
    productCardParams(item) {
      return {
        item,
        sectionPath: item.section_path,
        categoryPath: item.category_path,
        productPath: item.seo_path,
      };
    },
  },
  computed: {
    ...mapGetters("account", ["accountInfo", "pointsNumber"]),
  },
  mounted() {
    this.fetchData();
  },
  metaInfo() {
    return {
      title: `Профиль - История заказов - ЛУКМАРКЕТ`,
    };
  },
};
</script>

<style scoped lang="scss">
.ss-header {
  padding: 20px 0;
  border-bottom: 2px solid #f5f5f5;
}

.table {
  position: relative;
}
.accordion-body {
  white-space: normal !important;
}

.accordion {
  &-header {
    position: relative;

    .table {
      white-space: nowrap;
    }
  }

  &-toggle {
    &:after {
      content: "";
      position: absolute;
      right: 15px;
      top: 16px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(225deg);
      vertical-align: middle;
      color: #555;
    }
  }
}

.nav-tabs {
  flex-wrap: nowrap;
}

.nav-item {
  display: inline-block !important;
  margin: 0;
}

.accordion-body {
  border-top: 2px solid #4a4a4a;
  border-bottom: 2px solid #4a4a4a;
}

.product-order-status {
  vertical-align: middle;
  position: relative;
  margin-left: 10px;
  padding-left: 35px;

  @media (min-width: 992px) {
    margin-left: 0;
  }

  &.canceled {
    color: #ff103e;
  }

  &.done:before {
    background-image: url("../../../assets/images/correct.svg");
  }

  &.accepted:before {
    background-image: url("../../../assets/images/wallet.svg");
    background-repeat: no-repeat;
  }

  &.payed:before {
    background-image: url("../../../assets/images/credit-card.svg");
    background-repeat: no-repeat;
  }

  &.processing:before {
    background-image: url("../../../assets/images/work.svg");
    background-repeat: no-repeat;
  }

  &.shipping:before {
    background-image: url("../../../assets/images/package.svg");
    background-repeat: no-repeat;
  }

  &.shipped:before {
    background-image: url("../../../assets/images/tag.svg");
    background-repeat: no-repeat;
  }

  &.canceled:before {
    background-image: url("../../../assets/images/cancel.svg");
    background-repeat: no-repeat;
  }
}

.product-order-status:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: -5px;
  width: 24px;
  height: 24px;
}

.visit-request-order-status {
  vertical-align: middle;
  position: relative;
  margin-left: 25px;

  &.canceled {
    color: #ff103e;
  }

  &.accepted:before {
    background-image: url("../../../assets/images/wallet.svg");
    background-repeat: no-repeat;
  }

  &.processing:before {
    background-image: url("../../../assets/images/work.svg");
    background-repeat: no-repeat;
  }

  &.confirmed:before {
    background-image: url("../../../assets/images/tag.svg");
    background-repeat: no-repeat;
  }

  &.done:before {
    background-image: url("../../../assets/images/correct.svg");
  }

  &.canceled:before {
    background-image: url("../../../assets/images/cancel.svg");
    background-repeat: no-repeat;
  }
}

.visit-request-order-status:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -35px;
  top: -5px;
  width: 24px;
  height: 24px;
}

.accordion-header {
  cursor: pointer;
}

.table {
  font-size: 16px;
  min-width: 385px;
}

.table a {
  font-weight: normal;
}

.table thead th {
  border: none !important;
}

.prod-table.table th,
.prod-table.table td {
  width: 25% !important;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.product-order_number {
  font-weight: bold;
}

.addr {
  margin-left: 10px;
  padding-left: 40px;
  position: relative;

  @media (max-width: 992px) {
    margin-top: 10px;
  }
}

.addr:before {
  content: url("../../../assets/images/placeholder.svg");
  position: absolute;
  left: 0;
  top: 4px;
}

.desc-title {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
}

.additional-info-text {
  margin: 0 13px;
  font-size: 18px;
}

.flex-table {
  display: flex;
  flex-direction: column;

  &-header {
    font-weight: bold;
    align-items: center;

    @media (max-width: 992px) {
      display: none !important;
    }
  }

  &-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 992px) {
      flex-direction: row;
    }

    &.collapse {
      display: none;
    }

    &.collapse.show {
      display: flex;
    }
  }

  &-cell {
    padding: 0.5rem;
    word-wrap: break-word;

    @media (min-width: 992px) {
      padding: 1rem;
      width: calc(100% / 4);
    }
  }
}

.accordion {
  &-header {
    @media (max-width: 992px) {
      padding: 0.5rem 0;
    }
  }

  &-item {
    border-top: 1px solid #ddd;

    &:first-child {
      @media (max-width: 992px) {
        border-top: none;
      }
    }
  }

  &-body {
    white-space: nowrap;
  }
}
.table td,
.table th {
  width: 15%;
}
</style>
